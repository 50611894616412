import get from '@invitato/helpers/dist/getLocale';

export default {
  title: get('Konfirmasi', 'Confirmation'),
  desc: get(
    `Kami sangat berterima kasih kepada Bapak/Ibu/Saudara/i yang berkenan memberikan tanda kasih kepada
  kami. Silakan melakukan konfirmasi pada kolom di bawah untuk mempermudah pendataan:`,
    `We are truly grateful to the loved one who are willing to give us a sign of sincere love. Please make your confirm in the form below to make it easier for us to do tracking:`,
  ),
  name: get('Nama', 'Name'),
  hp: get('Nomor HP', 'Phone Number'),
  giftType: get('Tipe Tanda Kasih', 'Wedding Gift Type'),
  bank: get('Bank Transfer', 'Bank Transfer'),
  gift: get('Hadiah Fisik', 'Physical Gift'),
  notes: get('Tulis Catatan', 'Add Notes'),
  additionalNotes: get(
    `*) Tambahkan catatan seperti nomor resi, nomor rekening dan lain sebagainya.`,
    `*) Please mention the airway bill, bank accounts, etc`,
  ),
  sendConfirm: get('Kirim Konfirmasi', 'Send Confirmation'),
  backToHome: get('Kembali ke Homepage', 'Back to Invitation'),
  fillNameFirst: get('Kamu perlu mengisi Nama terlebih dahulu.', 'You need input your name first.'),
  successSent: get(
    'Terima kasih sudah melakukan konfirmasi pengiriman hadiah.',
    'Thank you for your confirmation',
  ),
  submitted: get('Sukses', 'Submitted!'),
  failedSent: get(
    'Terjadi kesalahan. Mohon refresh browser Anda atau coba lagi nanti.',
    'Something wrong happened. Please try again later or refresh your browser!',
  ),
};
